import { useState } from 'react'
import Navbar from '../components/Navbar'
import Slider from '../components/Slider'
import appleIcon from '../assets/images/apple.png'
import playstoreIcon from '../assets/images/android.png'
import androidVideo from '../assets/videos/android.mp4'
import ios from '../assets/videos/ios.mp4'

const Home = () => {
  
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  const [activeTab, setActiveTab] = useState('android');
  const [videoSource, setVideoSource] = useState(androidVideo)


  const handler = (e) => {
    setActiveTab(activeTab === 'android' ? 'ios' : 'android')
    setVideoSource(videoSource === androidVideo ? ios : androidVideo)
  }

  return (
    <div className='overflow-hidden' >
      <Navbar />


      <section className='flex flex-col items-center h-[30.26vw] ' id='section-1'></section>

      <section className='flex flex-col items-center justify-center bg-[#4B2C8C] sm:px-3 ' id='section-2'>
        <div className='relative  mb-3' >
          <img src={require('../assets/images/section-2-img-1.png')} className='' alt='' />
        </div>
        <div className='lg:mb-8 sm:mt-16 mt-5' >
          <h1 className='lg:text-6xl text-3xl text-center  lg:mb-8  text-white text-transparent ' >BAHİSTE ÇIĞIR AÇAN SEÇENEKLER</h1>
          <p className='lg:text-2xl mt-5 text-center text-white px-9' >OlaBahis’te yüzlerce farklı spor müsabakasına en iyi oranlarla bahis yapın, en güncel oyunları deneyimleyin ya da güncel slot oyunları ile çok hızlı bir şekilde kazanın! OlaBahis’in %100 güvenilir alt yapısı ve kullanıcı dostu arayüzü ile kazanmaya davetlisiniz. Ayrıca ilk üyelik bonusunuz hazır!</p>
        </div>
      </section>

      <section className=' bg-gradient-to-b from-[#4B2C8C] to-white lg:mb-10'>
        <div className='p-4 lg:px-32' >
          <div className='grid lg:grid-cols-4 grid-cols-2 bg-[#4B2C8C] gap-3 lg:p-16 p-5 rounded-3xl border-2 ' style={{ borderColor: 'white' }}>
            <div className='lg:max-w-[213px] max-w-[150px] mx-auto ' >
              <div className='flex items-center justify-center mb-5' >
                <img src={require('../assets/images/Rectangle 18118.png')} />
              </div>
              <h3 className='text-white font-bold text-center mb-5 sm:text-2xl' >Bahislerde Yüksek Oranlar</h3>
              <p className='text-white text-center'>Yüzlerce spor müsabakasından dilediğinize en yüksek oran avantajıyla bahis yapın.</p>
            </div>

            <div className='lg:max-w-[213px] max-w-[150px] mx-auto' >
              <div className='flex items-center justify-center mb-5' >
                <img src={require('../assets/images/Rectangle 18116.png')} />
              </div>
              <h3 className='text-white font-bold text-center mb-5  sm:text-2xl' >Bonuslar</h3>
              <p className='text-white text-center '>Sadece size özel bonus ve ödüllerden yararlanın!</p>
            </div>

            <div className='lg:max-w-[213px] max-w-[150px] mx-auto' >
              <div className='flex items-center justify-center mb-5' >
                <img src={require('../assets/images/Rectangle 18119.png')} />
              </div>
              <h3 className='text-white font-bold text-center mb-5  sm:text-2xl' >Yüksek Çeşitlilik</h3>
              <p className='text-white text-center'>Güncel oyunlar, yüzlerce spor müsabakası ve canlı casino deneyimi!</p>
            </div>

            <div className='lg:max-w-[213px] max-w-[150px] mx-auto' >
              <div className='flex items-center justify-center mb-5' >
                <img src={require('../assets/images/Rectangle 18117.png')} />
              </div>
              <h3 className='text-white font-bold text-center mb-5  sm:text-2xl' >Her An Güvenlik</h3>
              <p className='text-white text-center'>OlaBahis’te kolayca ve güven içinde yatırın ve çekin.!</p>
            </div>
          </div>
        </div>

      </section>

      <section className=' relative overflow-hidden  bg-white' id='google-slider'>
        <div className='lg:mb-10 mb-5 px-5 mt-12' >
          <h3 className='lg:text-5xl text-3xl text-center font-semibold text-black' >OLA <span className='text-[#FFB800]'>BAHİS</span> NASIL İNDİRİLİR?</h3>
        </div>
            <div className='flex flex-col-2 items-center justify-center mb-10'>
                 <img src={require('../assets/images/android.png')} alt="" />
                 <h1 className=' text-4xl font-thin mr-2 ml-3'>ANDROID</h1>
             </div>  
        <div className='lg:px-36 px-5 ' >
          <Slider platform='android' />
        </div>
      </section>

      <section className='lg:mb-24 mb-10 overflow-hidden relative  bg-white' id='appstore-slider'>
        <div className='lg:mb-10 mb-5 px-5 mt-20' >
        <h3 className='lg:text-5xl text-3xl text-center font-semibold text-black' >OLA <span className='text-[#FFB800]'>BAHİS</span> NASIL İNDİRİLİR?</h3>
        <div className='flex flex-col-2 items-center justify-center mb-10 mt-10'>
                 <img src={require('../assets/images/apple.png')} alt="" />
                 <h1 className=' text-4xl font-thin mr-2 ml-3'>IOS</h1>
             </div>   
        </div>
        <div className='lg:px-36 px-5 ' >
          <Slider platform='ios' />
        </div>
      </section>

      <section className='bg-gradient-to-b from-[#4500D7] to-[#AD86FF] flex flex-col' >
      <h1 className='lg:text-6xl text-3xl text-center  lg:mb-16  text-white text-transparent mt-20' >OLABAHİS NASIL İNDRİLİLİR?
<br /><span className=' font-light'>VİDEO</span></h1>

        <div className='m-auto flex gap-5 p-1 rounded-2xl border-2 bg-[#FBCA34] mb-10'  style={{ borderColor: '#FBCA34' }}>

          <div
            className={`flex justify-center p-3 rounded-2xl ${activeTab === 'android' ? 'bg-white hover:bg-' : ''}`}
            onClick={handler}>
            <img
              src={playstoreIcon}
              className='mr-3 text-white'
            />
            <p className='uppercase  text-[#FBCA34] self-center'onClick={handleClick}
        style={{ color: active ? "white" : "#FBCA34" }} >android</p>
          </div>


          <div
            className={`flex justify-center p-3 rounded-full ${activeTab === 'ios' ? 'bg-white hover:bg-white ' : ''}`}
            onClick={handler} >
            <img
              src={appleIcon}
              className='mr-3'
            />
            <p className='uppercase  self-center ' onClick={handleClick}
        style={{ color: active ? "#FBCA34" : "white" }}>APPLE</p>
          </div>
        </div>
       
        <div className='mb-10 px-5' >
            <Player source={videoSource} key={activeTab}/>
          </div>

      </section>

      <footer className='bg-[#4B2C8C] h-auto ' >
         <div className='sm:grid grid-cols-2'>
          
          <div className='self-center'>
            <p className='text-left text-white sm:ml-[20%] pt-8  sm:w-1/2'>
            OlaBahis’te öncelik güvenlik! En güvenilir bahis deneyimini sunmak için teknolojik çözümleri ve güçlü altyapımızı kullanıyoruz.
            </p>
            <ul className='sm:ml-[22%] pt-8 text-white sm:w-1/2'>
              <li className='mt-1'><span>&middot;</span>Hızlı ve güvenli yatırma-çekme işlemleri</li>
              <li className='mt-1'><span>&middot;</span>Yüzlerce bahis ve oyun seçeneği</li>
              <li className='mt-1'><span>&middot;</span>Ödül ve bonuslar</li>
              <li className='mt-1'><span>&middot;</span>Yarışmalar</li>
              <li className='mt-1'><span>&middot;</span>Yüksek oranlar</li>
              <li className='mt-1'><span>&middot;</span>Spor müsabakaları, canlı bahis ve canlı casino, slotlar, oyunlar ve daha fazlası...</li>
            </ul>
           <p className='text-white sm:w-1/2 text-left sm:ml-[20%] mt-8 '>
           OlaBahis’e gelin, online bahis ve casino keyfinde yeniliği deneyimleyin!
           </p>
          </div>
          <div className='m-auto '>
          <img src={require('../assets/images/section-6-img-1.png')} className='' alt='' />
          </div>
         </div>
     
         <div className='md:ml-[10%] pb-4 '>
         <img src={require('../assets/images/logo-var1.png')} className='self-center mb-4' alt='' />
            
             <div className='gap-5 mb-5 flex flex-cols-2 '>
              <div className='max-w-100 flex flex-col-3   gap-2 sm:gap-5  items-center justify-center'>
                <img src={require('../assets/images/Vector(6).png')} className='' alt='' />
                <img src={require('../assets/images/Vector(7).png')} className='' alt='' />
                <img src={require('../assets/images/Vector(8).png')} className='' alt='' />
                </div>     
                <p className='text-[#DDDDDD] self-center text-center '>© 2023 OLABAHİS</p>

            </div>
          </div>
      </footer>

    </div>
  )
}

export default Home

const Player = ({ key, source }) => {
  return (
    <video key={key} class="lg:w-3/4 border m-auto border-gray-200 rounded-lg dark:border-gray-700" controls>
      <source src={source} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )
}
